import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { color, font, elevation } from '../styles'

const ButtonLink = styled(Link)`
  display: inline-block;
  background: ${color.brandPrimary};
  padding: 1rem 2.5rem;
  border-radius: 0.5rem;
  box-shadow: ${elevation.button};
  ${font.button}
  color: ${color.primary};
  cursor: pointer;
  margin: 0 auto rem auto;
  transition: transform 0.16s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
`

const Button = ({ to, children }) => {
  return (
    <ButtonLink to={to}>
      {children}
    </ButtonLink>
  )
}

export default Button