import * as React from 'react'
import styled from 'styled-components'
import { color, font } from '../styles'
import { Helmet } from 'react-helmet'
import Button from '../components/button'
import svgLogo from '../components/logo.svg'

const Wrapper = styled.main`
  max-width: 42.5rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  @media (min-width: 1280px) {
    padding: 8rem 4rem;
  }
  @media (max-width: 1279px) {
    padding: 1rem;
  }
`
const Logo = styled.img`
  @media (min-width: 1280px) {
    width: 6rem;
    margin-bottom: 1.5rem;
  }
  @media (max-width: 1279px) {
    width: 4rem;
    margin-bottom: 1rem;
  }
`
const Header = styled.h1`
  text-align: center;
  margin-bottom: 1rem;
`
const Subheader = styled.p`
  ${font.p}
  text-align: center;
  color: ${color.primary};
  margin-bottom: 2rem;
`

const IndexPage = () => {
  return (
    <Wrapper>
      <Helmet>
        <title>{`Home | Design Foundations`}</title>
        <meta name="description" content={`A free introduction to product design. Learn the core concepts in visual design, interaction design, product management, and user research.`} />
      </Helmet>
      <Logo src={svgLogo} />
      <Header>Design Foundations</Header>
      <Subheader>A free introduction to product design. Learn the core concepts in visual design, interaction design, product management, and user research.</Subheader>
      <Button to="/introduction/introduction/">Start Learning</Button>
    </Wrapper>
  )
}

export default IndexPage
